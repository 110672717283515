import styled from 'styled-components';

import InstallmentItem from './InstallmentItem';

const InstallmentItemsContainer = styled('div')`
  > ${InstallmentItem} {
    border-top: 1px solid ${(props) => props.theme.colors.grey3};
    padding: 12px 8px;

    &:last-child {
      border-bottom: 1px solid ${(props) => props.theme.colors.grey3};
    }
  }
`;

export default InstallmentItemsContainer;
