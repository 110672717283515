import React from 'react';

import Layout from '../components/elements/Layout';
import Providers from '../components/elements/Layout/Providers';
import HomePage from '../containers/HomePage';

export interface IHomePageProps {}

const Home = (props: IHomePageProps) => (
  <Providers>
    <Layout showFooter>
      <HomePage {...props} />
    </Layout>
  </Providers>
);

export const getStaticProps = async (): Promise<IHomePageProps> => {
  return {
    props: {},
  };
};

Home.requireAuth = 'ACTIVE';

export default Home;
