import type { ICurrentUserValues } from '$/hooks/useCurrentUser';

import ButtonContainer from '../../YourFinancingPage/ButtonContainer';

import theme from '$/assets/theme';
import Hr from '$/components/blocks/OfferPage/Hr';
import Button from '$/components/elements/Button';
import OfferSection from '$/components/elements/OfferSection';
import { Sub2Typography } from '$/components/elements/Typography';
import { YearUpdateStatus } from '$/types/enums/YearlyUpdateStatus';

interface IYearlyUpdateNotificationProps {
  yearUpdate: ICurrentUserValues['yearlyUpdatePending'][number];
}

const YearlyUpdateNotification = ({
  yearUpdate,
}: IYearlyUpdateNotificationProps) => (
  <OfferSection
    title={'Jahres-Update'}
    backgroundColor={theme.colors.strawberry10}
  >
    <Sub2Typography style={{ margin: '8px 16px' }}>
      Bitte teile uns dein Update für das Kalenderjahr {yearUpdate.year} bis zum
      30.04.
      {yearUpdate.year > new Date().getFullYear()
        ? yearUpdate.year + 1
        : new Date().getFullYear()}{' '}
      mit.
    </Sub2Typography>
    <Hr style={{ margin: '8px 0' }} />
    <ButtonContainer columns={'1fr'} style={{ margin: '12px 0 0' }}>
      <Button
        variant={'primary'}
        title={'Update mitteilen'}
        colorStates={{
          unset: 'strawberry75',
          hover: 'strawberry',
          active: 'strawberry',
        }}
        link={{
          href:
            yearUpdate.IdStatus === YearUpdateStatus.NOT_STARTED
              ? `/yearly-update?year=${yearUpdate.year}`
              : `/yearly-update?year=${yearUpdate.year}&step=activity`,
        }}
      />
    </ButtonContainer>
  </OfferSection>
);

export default YearlyUpdateNotification;
