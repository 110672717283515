import styled from 'styled-components';

import { Sub2Typography } from '../Typography';

const Wrapper = styled('div')`
  > * {
    &:first-child {
      &${Sub2Typography} {
        margin-top: 32px;
      }

      &:not(${Sub2Typography}) {
        padding-top: 32px;
      }
    }

    &:last-child {
      padding-bottom: 32px;
    }
  }
`;

export default Wrapper;
