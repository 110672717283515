import type { PendingSemesterUpdate, UserData } from '$/API';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';

import ButtonDescriptionText from '../../SettingsPage/ButtonDescriptionText';
import ButtonHeaderText from '../../SettingsPage/ButtonHeaderText';
import ButtonContainer from '../../YourFinancingPage/ButtonContainer';

import theme from '$/assets/theme';
import Hr from '$/components/blocks/OfferPage/Hr';
import Button from '$/components/elements/Button';
import OfferSection from '$/components/elements/OfferSection';
import { Sub2Typography } from '$/components/elements/Typography';

interface ISemesterUpdateNotificationProps {
  semesterUpdate: PendingSemesterUpdate;
  additionalDocuments: UserData['additionalDocuments'];
}

const SEMESTER_UPDATE_TYPES = {
  SUMMER: 1,
  WINTER: 2,
};

const SEMESTER_UPDATE_STATUS = {
  SENT: 1,
  COMPLETED: 2,
  MISSING_DOCUMENTS: 3,
};

const SEMESTER_UPDATE_DATES = {
  [SEMESTER_UPDATE_TYPES.SUMMER]: '30. April',
  [SEMESTER_UPDATE_TYPES.WINTER]: '30. Oktober',
};

const SemesterUpdateNotification = ({
  semesterUpdate,
  additionalDocuments,
}: ISemesterUpdateNotificationProps) => {
  const semesterUpdateDate = useMemo(
    (): string => SEMESTER_UPDATE_DATES[semesterUpdate.semesterType] ?? '',
    [semesterUpdate.semesterType]
  );

  const semesterUpdateMissingDocuments = useMemo(
    () => semesterUpdate.status === SEMESTER_UPDATE_STATUS.MISSING_DOCUMENTS,
    [semesterUpdate]
  );

  const semesterUpdateItems = useMemo((): Array<{
    title: string;
    description: string;
  }> => {
    const semesterUpdateItems: Array<{
      title: string;
      description: string;
    }> = [];

    if (semesterUpdate.semesterType && additionalDocuments) {
      const semesterDescriptionString =
        semesterUpdate.semesterType === SEMESTER_UPDATE_TYPES.WINTER
          ? `für Wintersemester ${semesterUpdate.semesterYear}`
          : `für Sommersemester ${semesterUpdate.semesterYear}`;

      for (const { semesterUpdateId, documentName } of additionalDocuments) {
        if (semesterUpdateId === semesterUpdate.id) {
          if (documentName && semesterDescriptionString) {
            semesterUpdateItems.push({
              title: documentName,
              description: semesterDescriptionString,
            });
          }
        }
      }
    }

    return semesterUpdateItems;
  }, [additionalDocuments]);

  return (
    <OfferSection
      title={'Semester Update'}
      backgroundColor={theme.colors.strawberry10}
    >
      <Sub2Typography style={{ margin: '8px' }}>
        Wie läuft es bei dir im Studium? Das wollen wir mit dem Semester-Update
        erfahren. Bitte gib uns dein Update bis zum {semesterUpdateDate}.
      </Sub2Typography>
      <Hr style={{ margin: '8px 0' }} />
      <ButtonContainer columns="1fr" style={{ margin: '8px 0 12px' }}>
        {semesterUpdateItems.map((item) => (
          <React.Fragment key={item.title}>
            <Button
              title={
                <>
                  <ButtonHeaderText>{item.title}</ButtonHeaderText>
                  <ButtonDescriptionText>
                    {item.description}
                  </ButtonDescriptionText>
                </>
              }
              variant="trueTransparent"
              textProps={{
                style: {
                  textAlign: 'left',
                },
              }}
              buttonProps={{
                style: {
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr auto',
                  justifyContent: 'unset',
                  alignItems: 'unset',
                  color: theme.colors.grey8,
                  padding: '0 8px',
                  cursor: 'unset',
                },
              }}
              iconContainerProps={{
                style: {
                  alignItems: 'flex-start',
                  paddingTop: '5px',
                },
              }}
              leftIcon={<FontAwesomeIcon icon="file" />}
            />
            <Hr style={{ margin: '8px 0' }} />
          </React.Fragment>
        ))}
      </ButtonContainer>
      <ButtonContainer columns={'1fr'} style={{ margin: '12px 0 0' }}>
        <Button
          variant={'primary'}
          title={'Update mitteilen'}
          colorStates={{
            unset: 'strawberry75',
            hover: 'strawberry',
            active: 'strawberry',
          }}
          link={{
            href: semesterUpdateMissingDocuments
              ? `/semester-update?step=missingDocuments&semesterId=${semesterUpdate.id}`
              : `/semester-update?semesterId=${semesterUpdate.id}`,
          }}
        />
      </ButtonContainer>
    </OfferSection>
  );
};

export default SemesterUpdateNotification;
