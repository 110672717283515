import styled from 'styled-components';

import { PTypography } from '../../elements/Typography';

const ButtonHeaderText = styled(PTypography)`
  color: ${(props) => props.theme.colors.grey7};
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.14px;
`;

export default ButtonHeaderText;
