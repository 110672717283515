/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type UserDataInput = {
  email?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  birthDate?: string | null,
  englishLevel?: number | null,
  expectedDegreeId?: number | null,
  previousEducation?: number | null,
  schoolNote?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  previousStudies?: boolean | null,
  previousStudiesDegreeId?: number | null,
  previousStudiesGrade?: number | null,
  cityAlt?: string | null,
  universityId?: number | null,
  universityAlt?: string | null,
  majorId?: number | null,
  majorAlt?: string | null,
  subjectId?: number | null,
  subjectAlt?: string | null,
  variantId?: number | null,
  salutationId?: number | null,
  dbTrackingId?: string | null,
  campaignToken?: string | null,
  ectsPoints?: number | null,
  ectsPointsTotal?: number | null,
  averageNote?: number | null,
  currentSemesterNumber?: number | null,
  countryId?: number | null,
  city?: string | null,
  zip?: string | null,
  homeNumber?: string | null,
  street?: string | null,
  additionalAddress?: string | null,
  phone?: string | null,
  nationalityId?: number | null,
  birthCountryId?: number | null,
  birthPlace?: string | null,
  childrenCount?: number | null,
  residenceStatus?: number | null,
  remainingSemesterNumber?: number | null,
  personalInfosStepRejectedStatus?: number | null,
  restrictedStay?: string | null,
};

export type UserDataCreateResponse = {
  __typename: "UserDataCreateResponse",
  id: number,
};

export type UpdateUserDataInput = {
  status?: number | null,
  firstName?: string | null,
  lastName?: string | null,
  birthDate?: string | null,
  englishLevel?: number | null,
  expectedDegreeId?: number | null,
  previousEducation?: number | null,
  schoolNote?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  previousStudies?: boolean | null,
  previousStudiesDegreeId?: number | null,
  previousStudiesGrade?: number | null,
  cityAlt?: string | null,
  universityId?: number | null,
  universityAlt?: string | null,
  majorId?: number | null,
  majorAlt?: string | null,
  fieldId?: number | null,
  fieldAlt?: string | null,
  variantId?: number | null,
  gender?: number | null,
  dbTrackingId?: string | null,
  campaignToken?: string | null,
  ectsPoints?: number | null,
  ectsPointsTotal?: number | null,
  averageNote?: number | null,
  currentSemesterNumber?: number | null,
  countryId?: number | null,
  city?: string | null,
  zip?: string | null,
  homeNumber?: string | null,
  street?: string | null,
  additionalAddress?: string | null,
  phone?: string | null,
  nationalityId?: number | null,
  birthCountryId?: number | null,
  birthPlace?: string | null,
  childrenCount?: number | null,
  residenceStatus?: number | null,
  remainingSemesterNumber?: number | null,
  personalInfosStepRejectedStatus?: number | null,
  restrictedStay?: string | null,
};

export type OkCognitoResponse = {
  __typename: "OkCognitoResponse",
  ok: boolean,
};

export type UserAbroadDataInput = {
  type: number,
  countryId: number,
  cityId?: number | null,
  cityAlt?: string | null,
  universityId?: number | null,
  universityAlt?: string | null,
  startDate: string,
  endDate: string,
};

export type AbroadDataSaveResponse = {
  __typename: "AbroadDataSaveResponse",
  id: number,
};

export type UserChangeDataInput = {
  firstName?: string | null,
  lastName?: string | null,
  nationalityId?: number | null,
  salutationId?: number | null,
  childrenCount?: number | null,
  street?: string | null,
  homeNumber?: string | null,
  additionalAddress?: string | null,
  zip?: string | null,
  city?: string | null,
  countryId?: number | null,
  phone?: string | null,
  iban?: string | null,
  bank?: string | null,
  bic?: string | null,
};

export type UserChangeInputResponse = {
  __typename: "UserChangeInputResponse",
  id: number,
};

export type UserChangeContactDataInput = {
  street?: string | null,
  homeNumber?: string | null,
  additionalAddress?: string | null,
  zip?: string | null,
  city?: string | null,
  countryId?: number | null,
  phone?: string | null,
  email?: string | null,
  code?: string | null,
};

export type GenericResultResponse = {
  __typename: "GenericResultResponse",
  id: number,
};

export type UserChangeEmailConfirmInput = {
  code?: string | null,
};

export type UserFinancialDataInput = {
  iban: string,
  bank: string,
  bic: string,
};

export type FinancialDataSaveResponse = {
  __typename: "FinancialDataSaveResponse",
  id: number,
};

export type FinanceRequirementPackageInput = {
  startDate: string,
  endDate: string,
  paysTuitionFees?: number | null,
  tuitionFees?: number | null,
  isChangeRequest?: boolean | null,
};

export type CreateFinanceRequirementItemInput = {
  // 1 - monthly / 13 - one time
  paymentRhythmId: number,
  moneyUsage: string,
  startDate: string,
  endDate: string,
  amount: number,
  isChangeRequest?: boolean | null,
};

export type CreateFinanceRequirementResponse = {
  __typename: "CreateFinanceRequirementResponse",
  id: number,
};

export type UpdateFinanceRequirementItemInput = {
  id?: number | null,
  paymentRhythmId: number,
  moneyUsage: string,
  startDate: string,
  endDate: string,
  amount: number,
};

export type UpdateFinanceRequirementResponse = {
  __typename: "UpdateFinanceRequirementResponse",
  id: number,
};

export type CreateDocumentInput = {
  key: string,
  name: string,
  field: string,
  fileNameOverride?: string | null,
  changeDataId?: number | null,
  semesterInfoId?: number | null,
  voluntaryUpdateId?: number | null,
  type?: string | null,
};

export type CreateDocumentsResponse = {
  __typename: "CreateDocumentsResponse",
  id?: number | null,
};

export type RemoveDocumentsResponse = {
  __typename: "RemoveDocumentsResponse",
  id?: number | null,
};

export type ApplicationSubmittedInput = {
  pepStatus: boolean,
};

export type ApplicationSubmittedResponse = {
  __typename: "ApplicationSubmittedResponse",
  id: number,
};

export type InitVerificationInput = {
  clientId: number,
  documentId: number,
};

export type InitVerificationResponse = {
  __typename: "InitVerificationResponse",
  id: string,
  url?: string | null,
};

export type FormDataContentInput = {
  label: string,
  value?: string | null,
  s3Key?: string | null,
};

export type RejectOfferResponse = {
  __typename: "RejectOfferResponse",
  id: number,
};

export type CognitoIdentityDataInput = {
  cognitoIdentity: string,
};

export type UpdateCognitoIdentityResponse = {
  __typename: "UpdateCognitoIdentityResponse",
  id: number,
};

export type VoluntaryUpdateInput = {
  currentStudiesStatusId?: number | null,
  currentStudiesStatusAlt?: string | null,
  currentStudiesOccupationId?: number | null,
  currentStudiesOccupationAlt?: string | null,
  afterStudiesStatusId?: number | null,
  afterStudiesStatusAlt?: string | null,
  afterStudiesOccupationId?: number | null,
  afterStudiesOccupationAlt?: string | null,
  afterStudiesStudyDegreeId?: number | null,
};

export type VoluntaryUpdateStatusInput = {
  status: number,
};

export type UserChangeContractInput = {
  firstName?: string | null,
  lastName?: string | null,
  nationalityId?: number | null,
  salutationId?: number | null,
  childrenCount?: number | null,
  street?: string | null,
  homeNumber?: string | null,
  additionalAddress?: string | null,
  zip?: string | null,
  contactCity?: string | null,
  countryId?: number | null,
  phone?: string | null,
  averageNote?: number | null,
  ectsPoints?: number | null,
  ectsPointsTotal?: number | null,
  currentSemesterNumber?: number | null,
  cityAlt?: string | null,
  universityId?: number | null,
  universityAlt?: string | null,
  majorId?: number | null,
  majorAlt?: string | null,
  fieldId?: number | null,
  fieldAlt?: string | null,
  endDate?: string | null,
  reason?: string | null,
  expectedDegreeId?: number | null,
  studiesResignation?: boolean | null,
  studiesResignationDate?: string | null,
};

export type UserChangeContractResponse = {
  __typename: "UserChangeContractResponse",
  id: number,
};

export type SemesterUpdateInput = {
  id: number,
  activity: number,
  status: number,
  currentSemesterNumber?: number | null,
  remainingSemesterNumber?: number | null,
  averageGrade?: number | null,
  ectsPoints?: number | null,
  endDate?: string | null,
  internshipType?: number | null,
  notStudiedType?: number | null,
  notStudiedCanContinue?: number | null,
  finalGrade?: number | null,
  customReason?: string | null,
  documents?: Array< SemesterUpdateDocumentInput | null > | null,
};

export type SemesterUpdateDocumentInput = {
  type: string,
  filename: string,
  s3Key: string,
};

export type UpdateClientEmailInput = {
  userId: number,
  newEmail: string,
};

export type UpdateClientEmailResponse = {
  __typename: "UpdateClientEmailResponse",
  id: number,
};

export type UpdateOfferStatusInput = {
  clientId: number,
  newStatus: number,
  versionId?: number | null,
};

export type UpdateOfferStatusResponse = {
  __typename: "UpdateOfferStatusResponse",
  id: number,
};

export type UpdateStatusInput = {
  clientId: number,
  newStatus: number,
  ResubmissionFS?: number | null,
  ResubmissionECTS?: number | null,
};

export type OkResponse = {
  __typename: "OkResponse",
  ok: boolean,
};

export type DeleteUserInput = {
  clientId: number,
};

export type DeleteUserResponse = {
  __typename: "DeleteUserResponse",
  id: number,
};

export type RejectChangeOfferResponse = {
  __typename: "RejectChangeOfferResponse",
  id?: number | null,
};

export type UpdateChangeOfferStatusInput = {
  clientId: number,
  newStatus: number,
};

export type UpdateChangeOfferStatusResponse = {
  __typename: "UpdateChangeOfferStatusResponse",
  id: number,
};

export type NotifyContactChangedResponse = {
  __typename: "NotifyContactChangedResponse",
  id: number,
};

export type CreateOfferInput = {
  clientId: number,
  contractDate: string,
  Rueckzahlbegin?: string | null,
  Rueckzahldauer?: number | null,
  Rueckzahlende?: string | null,
  Gehaltsanteil?: number | null,
  UeberEuribor?: number | null,
  MonateBisGehalt?: number | null,
  PersKarenzZeitInMonaten?: number | null,
  ErwZeitBisGehalt?: number | null,
  ZusErwerbslZeiten?: number | null,
  DauerBisBerufseinstieg?: number | null,
  GehaltNachGrunddaten?: number | null,
  Gehaltsinflation2?: number | null,
  GehaltMitInflation?: number | null,
  Korrekturfaktor?: number | null,
  Gehalt?: number | null,
  Gehaltssteigerung?: number | null,
  Perzentil25Prozent?: number | null,
  Perzentil75Prozent?: number | null,
  InternerZinssatz2?: number | null,
  AuditDaten?: string | null,
  USER_NR_NEW?: number | null,
  TS_NEW?: string | null,
  USER_NR_CHANGE?: number | null,
  TS_CHANGE?: string | null,
  DELETED?: number | null,
  FkAbiNote?: number | null,
  FkAbiNoteIndex?: number | null,
  FkAusbildung?: number | null,
  FkAusbildungIndex?: number | null,
  FkAusland?: number | null,
  FkAuslandIndex?: number | null,
  FkEngagement?: number | null,
  FkEngagementIndex?: number | null,
  FkGeschlecht?: number | null,
  FkGeschlechtIndex?: number | null,
  FkMobilitaet?: number | null,
  FkMobilitaetIndex?: number | null,
  FkNote?: number | null,
  FkNoteIndex?: number | null,
  FkPraktika?: number | null,
  FkPraktikaIndex?: number | null,
  FkSprache?: number | null,
  FkSpracheIndex?: number | null,
  FkStudiendauer?: number | null,
  FkStudiendauerIndex?: number | null,
  ID_Studienabschluss?: number | null,
  ID_Fachrichtung?: number | null,
  ID_Spezialisierung?: number | null,
  GehaltsanteilCostup?: number | null,
  IdFond?: number | null,
  ID_StatusKoKriterien?: number | null,
  Aufschlagswert?: number | null,
  Zeitraumbegrenzung?: number | null,
  GehaltChanceKorrMittel?: number | null,
  DatumUnterschrift?: string | null,
  Maximaleinkommen?: number | null,
};

export type CreateOfferResponse = {
  __typename: "CreateOfferResponse",
  id: number,
};

export type SyncSemesterUpdateResponse = {
  __typename: "SyncSemesterUpdateResponse",
  count?: number | null,
};

export type TestOfferSignedInput = {
  clientId: number,
  documentId: number,
};

export type TestOfferSignedResponse = {
  __typename: "TestOfferSignedResponse",
  id: number,
};

export type MockOfferSignedInput = {
  clientId: number,
  documentId: number,
};

export type MockOfferSignedResponse = {
  __typename: "MockOfferSignedResponse",
  id: number,
};

export type AdditionalDocumentInput = {
  id: number,
  key: string,
  name: string,
  field: string,
  additionalDocumentId?: number | null,
  semesterInfoId?: number | null,
};

export type AdditionalDocumentsResponse = {
  __typename: "AdditionalDocumentsResponse",
  ids: Array< number | null >,
};

export type MockRequireSemesterUpdateInput = {
  clientId: number,
  semesterId: number,
};

export type MockRequireSemesterUpdateResponse = {
  __typename: "MockRequireSemesterUpdateResponse",
  id: number,
};

export type CustomerIoUnsubscribeInput = {
  clientId: number,
  unsubscribed: boolean,
};

export type CustomerIoUnsubscribeResponse = {
  __typename: "CustomerIoUnsubscribeResponse",
  id: number,
};

export type MockRequireYearUpdateInput = {
  clientId: number,
  year: number,
};

export type MockRequireYearUpdateResponse = {
  __typename: "MockRequireYearUpdateResponse",
  id: number,
};

export type YearUpdateInput = {
  year: number,
  IdStatus: number,
  wasEmployed: boolean,
  wasFreelancer: boolean,
  wasUnemployed: boolean,
  wasStudying: boolean,
  hadParentalLeave: boolean,
  numberOfJobs?: number | null,
  jobs: Array< YearUpdateJobInput | null >,
  unemployed?: YearUpdateUnemployedInput | null,
  studied?: YearUpdateStudiedInput | null,
  parentalLeave?: YearUpdateParentalLeaveInput | null,
};

export type YearUpdateJobInput = {
  type: number,
  dateStart?: string | null,
  dateEnd?: string | null,
  currencyCode: string,
  bonusSalary?: number | null,
  employerName?: string | null,
  jobDescription?: string | null,
  constantIncome: boolean,
  months: Array< YearUpdateMonthInput | null >,
};

export type YearUpdateMonthInput = {
  year: number,
  month: number,
  salaryAmount: number,
  workingHours?: number | null,
};

export type YearUpdateUnemployedInput = {
  officiallyUnemployed: boolean,
  dateFrom?: string | null,
  dateTo?: string | null,
};

export type YearUpdateStudiedInput = {
  dateFrom: string,
  dateTo: string,
};

export type YearUpdateParentalLeaveInput = {
  dateFrom: string,
  dateTo: string,
};

export type InitContactDataChangeInput = {
  email: string,
};

export type FormDataInput = {
  formName: string,
  data: Array< FormDataContentInput >,
  emailRecipient?: string | null,
};

export type GenericSuccessResponse = {
  __typename: "GenericSuccessResponse",
  success: boolean,
  message?: string | null,
};

export type customerPortalMediaLibraryAddFavoriteArticleInput = {
  contentfulArticleId: string,
};

export type customerPortalMediaLibraryAddWatchedArticleInput = {
  contentfulArticleId: string,
  watchTime?: number | null,
};

export type customerPortalMediaLibraryDeleteFavoriteArticleInput = {
  contentfulArticleId: string,
};

export type customerPortalMediaLibraryDeleteWatchedArticleInput = {
  contentfulArticleId: string,
};

export type customerPortalMediaLibraryUpdateWatchedArticleInput = {
  contentfulArticleId: string,
  watchTime?: number | null,
};

export type createTravelExpensesRefundProposalInput = {
  clientId: number,
  eventId: string,
  expirationDate?: string | null,
};

export type CreateTravelExpensesRefundProposalResponse = {
  __typename: "CreateTravelExpensesRefundProposalResponse",
  id: number,
  clientId: number,
  eventId: string,
  expirationDate: string,
};

export type updateTravelExpensesRefundInput = {
  travelExpensesRefundId: number,
  expirationDate?: string | null,
  refundSum?: number | null,
  formData?: string | null,
};

export type GenericAnonymousSuccessResponse = {
  __typename: "GenericAnonymousSuccessResponse",
  success: boolean,
  message?: string | null,
};

export type askForAdditionalDocumentsInput = {
  clientId: number,
  additionalDocumentsTypeIds?: Array< number > | null,
};

export type saveAdblockDetectionInput = {
  value: boolean,
};

export type addContractVersionInput = {
  contractType: number,
  contractVariant: number,
  contractVersion: string,
  nameOfContractVersion: string,
  templateFilename: string,
  templateLocation: string,
  validFrom?: string | null,
  validUntil?: string | null,
  comment?: string | null,
  ruleset?: string | null,
};

export type AddContractVersionResponse = {
  __typename: "AddContractVersionResponse",
  id: number,
};

export type updateContractVersionInput = {
  id: number,
  contractType?: number | null,
  contractVariant?: number | null,
  contractVersion?: string | null,
  nameOfContractVersion?: string | null,
  templateFilename?: string | null,
  templateLocation?: string | null,
  validFrom?: string | null,
  validUntil?: string | null,
  comment?: string | null,
  ruleset?: string | null,
};

export type UpdateContractVersionResponse = {
  __typename: "UpdateContractVersionResponse",
  success: boolean,
};

export type deleteContractVersionInput = {
  id: number,
};

export type DeleteContractVersionResponse = {
  __typename: "DeleteContractVersionResponse",
  success: boolean,
};

export type calculateTimeBufferCostInput = {
  BZP?: DurationInput | null,
  MZP?: DurationInput | null,
  ZZP?: DurationInput | null,
  BPA?: DurationInput | null,
  SKS?: DurationInput | null,
  SBA?: DurationInput | null,
  SEZ?: DurationInput | null,
};

export type DurationInput = {
  duration: number,
};

export type CalculateTimeBufferCostResponse = {
  __typename: "CalculateTimeBufferCostResponse",
  totalRepaymentPeriodExtension: number,
};

export type CityAutocompleteResponse = {
  __typename: "CityAutocompleteResponse",
  id: string,
  value: string,
};

export type HighSchoolAutocompleteResponse = {
  __typename: "HighSchoolAutocompleteResponse",
  id: number,
  value: string,
};

export type SpecializationAutocompleteResponse = {
  __typename: "SpecializationAutocompleteResponse",
  id: number,
  value: string,
  subjectId?: number | null,
};

export type MostPopularSpecializationsResponse = {
  __typename: "MostPopularSpecializationsResponse",
  id: number,
  value: string,
  subjectId: number,
};

export type SubjectAutocompleteResponse = {
  __typename: "SubjectAutocompleteResponse",
  id: number,
  value: string,
};

export type CountryAutocompleteResponse = {
  __typename: "CountryAutocompleteResponse",
  id: string,
  value: string,
};

export type GenericAutocompleteResponse = {
  __typename: "GenericAutocompleteResponse",
  id: string,
  value: string,
};

export type NationalityAutocompleteResponse = {
  __typename: "NationalityAutocompleteResponse",
  id: string,
  value: string,
  isEuropean: number,
};

export type LoanLimit = {
  __typename: "LoanLimit",
  minLoan?: number | null,
  maxLoan?: number | null,
};

export type AutomatedFeedbackInput = {
  majorId?: number | null,
  expectedDegreeId?: number | null,
  variantId?: number | null,
  fieldId?: number | null,
};

export type AutomatedFeedbackResponse = {
  __typename: "AutomatedFeedbackResponse",
  majorName?: string | null,
  majorId?: number | null,
  subjectId?: number | null,
  degreeId?: number | null,
  degreeTypeId?: number | null,
  variantId?: number | null,
  text: string,
  allowToProceed: boolean,
};

export type GetCurrenciesResponse = {
  __typename: "GetCurrenciesResponse",
  currencies:  Array<Currency >,
};

export type Currency = {
  __typename: "Currency",
  id: number,
  code: string,
  name: string,
  deleted: boolean,
};

export type GetIdForInput = {
  tableName: string,
  clientId: number,
  clientIdColumn: string,
};

export type GetIdForResponse = {
  __typename: "GetIdForResponse",
  id: number,
};

export type GetDegreesByIdsResponse = {
  __typename: "GetDegreesByIdsResponse",
  id: number,
  value: string,
};

export type DisabledServicePortalResponse = {
  __typename: "DisabledServicePortalResponse",
  disableServicePortal: boolean,
};

export type EmailNotExistInCognitoResponse = {
  __typename: "EmailNotExistInCognitoResponse",
  exist: boolean,
};

export type GetContractVersionForContentfulResponse = {
  __typename: "GetContractVersionForContentfulResponse",
  version: string,
};

export type PendingSemesterUpdate = {
  __typename: "PendingSemesterUpdate",
  id: number,
  semesterType: number,
  status?: number | null,
  semesterYear: number,
  studiesEnd: string,
};

export type NewCookieResponse = {
  __typename: "NewCookieResponse",
  encodedCookiePayload: string,
};

export type ContractData = {
  __typename: "ContractData",
  id?: number | null,
  filename?: string | null,
  dateSigned?: string | null,
  dateCreated?: string | null,
};

export type IbanValidationaResults = {
  __typename: "IbanValidationaResults",
  isValid: boolean,
  bankName?: string | null,
  bic?: string | null,
  bankCity?: string | null,
};

export type GetContractVersionResponse = {
  __typename: "GetContractVersionResponse",
  id: number,
  contractType: number,
  contractVariant: number,
  contractVersion: string,
  nameOfContractVersion: string,
  validFrom?: string | null,
  validUntil?: string | null,
  comment?: string | null,
  ruleset?: string | null,
};

export type TimeBufferEligibility = {
  __typename: "TimeBufferEligibility",
  isEligible: boolean,
  isBeforeRepaymentStartDate?: boolean | null,
};

export type CalculateCreditScoreInput = {
  clientId: number,
};

export type CalculateCreditScoreResponse = {
  __typename: "CalculateCreditScoreResponse",
  errorMessage?: string | null,
  scoreValue?: string | null,
  riskRatio?: string | null,
  scoreRange?: string | null,
  scoreText?: string | null,
  scoreInfoText?: Array< string > | null,
  birthDate?: string | null,
  birthPlace?: string | null,
  currentAddress?: CalculateCreditScoreAddress | null,
  previousAddress?: CalculateCreditScoreAddress | null,
};

export type CalculateCreditScoreAddress = {
  __typename: "CalculateCreditScoreAddress",
  street?: string | null,
  zipCode?: number | null,
  city?: string | null,
  country?: string | null,
};

export type CalculatePricingInput = {
  clientId: number,
  financingPackageId: number,
  incomePart?: number | null,
  additionalMonths?: number | null,
  expectedSalary?: number | null,
  contractSalary?: number | null,
};

export type CalculatePricingResponse = {
  __typename: "CalculatePricingResponse",
  income_part: number,
  repayment_period: number,
  contract_length: number,
  total_costs: number,
  over_under_founding: number,
  money_multiple: number,
  capping_limit: number,
  salary_share: number,
  annual_interest: number,
  estimated_income: number,
  contract_income: number,
  initial_income: number,
  inflation_rate: number,
};

export type CalculateExpectedSalaryInput = {
  clientId: number,
  date?: string | null,
};

export type CalculateExpectedSalaryResponse = {
  __typename: "CalculateExpectedSalaryResponse",
  salary: number,
  initialSalary: number,
  inflationRate: number,
  months: number,
};

export type UserData = {
  __typename: "UserData",
  id?: number | null,
  status?: number | null,
  salutationId?: number | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  englishLevel?: number | null,
  birthDate?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  contractDate?: string | null,
  cognitoUsername?: string | null,
  cognitoIdentity?: string | null,
  schoolNote?: number | null,
  variantId?: number | null,
  expectedDegreeId?: number | null,
  previousEducation?: PreviousEducationData | null,
  highSchool?: HighSchoolData | null,
  major?: MajorData | null,
  field?: FieldData | null,
  financingPackage?: FinancingPackageData | null,
  financingPackageChange?: FinancingPackageData | null,
  documents?:  Array<DocumentData | null > | null,
  consultant?: ConsultantData | null,
  contract?: ContractData | null,
  additionalDocuments?:  Array<AdditionalDocumentsData > | null,
  loanLimit?: UserLoanLimit | null,
  personalInfosStepRejectedStatus?: number | null,
  changeOfferStatus?: number | null,
  expectedDegree?: DegreeData | null,
  campaignToken?: string | null,
  customerCareStatus?: number | null,
  yearUpdate:  Array<YearUpdateData >,
  nationalityId?: number | null,
  nationality?: NationaliotyData | null,
  birthCountryId?: number | null,
  birthCountry?: CountryData | null,
  birthPlace?: string | null,
  childrenCount?: number | null,
  street?: string | null,
  homeNumber?: string | null,
  additionalAddress?: string | null,
  zip?: string | null,
  city?: string | null,
  countryId?: number | null,
  country?: CountryData | null,
  phone?: string | null,
  residenceStatus?: number | null,
  currentSemesterNumber?: number | null,
  remainingSemesterNumber?: number | null,
  ectsPoints?: number | null,
  ectsPointsTotal?: number | null,
  averageNote?: number | null,
  iban?: string | null,
  bank?: string | null,
  bic?: string | null,
  previousStudies?: boolean | null,
  previousStudiesDegree?: DegreeData | null,
  previousStudiesGrade?: number | null,
  universityId?: number | null,
  universityAlt?: string | null,
  restrictedStay?: string | null,
};

export type PreviousEducationData = {
  __typename: "PreviousEducationData",
  id?: number | null,
  name?: string | null,
};

export type HighSchoolData = {
  __typename: "HighSchoolData",
  id?: number | null,
  name?: string | null,
  city?: string | null,
};

export type MajorData = {
  __typename: "MajorData",
  id?: number | null,
  name?: string | null,
  subjectId?: number | null,
};

export type FieldData = {
  __typename: "FieldData",
  id?: number | null,
  name?: string | null,
};

export type FinancingPackageData = {
  __typename: "FinancingPackageData",
  id?: number | null,
  dateFrom?: string | null,
  dateTo?: string | null,
  amountTotal?: number | null,
  items?:  Array<FinancingPackageItem | null > | null,
  changeData?: FinancingPackageChangeData | null,
};

export type FinancingPackageItem = {
  __typename: "FinancingPackageItem",
  id?: number | null,
  type?: FinancingPackageItemType | null,
  dateFrom?: string | null,
  dateTo?: string | null,
  paymentRhythmId?: number | null,
  numberOfPayments?: number | null,
  amount?: number | null,
  amountTotal?: number | null,
  moneyUsage?: string | null,
  isChangeRequest?: boolean | null,
  status?: number | null,
};

export enum FinancingPackageItemType {
  MONTHLY = "MONTHLY",
  ONE_TIME = "ONE_TIME",
  YEARLY = "YEARLY",
  HALF_YEARLY = "HALF_YEARLY",
  OTHER = "OTHER",
}


export type FinancingPackageChangeData = {
  __typename: "FinancingPackageChangeData",
  id?: number | null,
  startStudies?: string | null,
  endStudies?: string | null,
};

export type DocumentData = {
  __typename: "DocumentData",
  id: number,
  name: string,
  key?: string | null,
  field: string,
  changeDataId?: number | null,
};

export type ConsultantData = {
  __typename: "ConsultantData",
  id?: number | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  phone?: string | null,
  imageUrl?: string | null,
};

export type AdditionalDocumentsData = {
  __typename: "AdditionalDocumentsData",
  ID: number,
  documentName: string,
  description?: string | null,
  allowMultiple?: boolean | null,
  semesterUpdateId?: number | null,
  documentTypeId: number,
  DocumentAlt?: string | null,
};

export type UserLoanLimit = {
  __typename: "UserLoanLimit",
  minLoan?: number | null,
  maxLoan?: number | null,
};

export type DegreeData = {
  __typename: "DegreeData",
  id?: number | null,
  name?: string | null,
};

export type YearUpdateData = {
  __typename: "YearUpdateData",
  year: number,
  IdStatus?: number | null,
  wasEmployed: boolean,
  wasFreelancer: boolean,
  wasUnemployed: boolean,
  wasStudying: boolean,
  hadParentalLeave: boolean,
  numberOfJobs?: number | null,
  jobs:  Array<YearUpdateJobType | null >,
  unemployed?: YearUpdateUnemployed | null,
  studied?: YearUpdateStudied | null,
  parentalLeave?: YearUpdateParentalLeave | null,
};

export type YearUpdateJobType = {
  __typename: "YearUpdateJobType",
  id: number,
  type: number,
  dateStart?: string | null,
  dateEnd?: string | null,
  currencyCode: string,
  totalSalary: number,
  bonusSalary?: number | null,
  employerName?: string | null,
  jobDescription?: string | null,
  constantIncome?: boolean | null,
  months:  Array<YearUpdateMonthType | null >,
};

export type YearUpdateMonthType = {
  __typename: "YearUpdateMonthType",
  id: number,
  year: number,
  month: number,
  salaryAmount: number,
  workingHours?: number | null,
};

export type YearUpdateUnemployed = {
  __typename: "YearUpdateUnemployed",
  officiallyUnemployed: boolean,
  dateFrom?: string | null,
  dateTo?: string | null,
};

export type YearUpdateStudied = {
  __typename: "YearUpdateStudied",
  dateFrom: string,
  dateTo: string,
};

export type YearUpdateParentalLeave = {
  __typename: "YearUpdateParentalLeave",
  dateFrom: string,
  dateTo: string,
};

export type NationaliotyData = {
  __typename: "NationaliotyData",
  id?: number | null,
  name?: string | null,
  isEuropean?: number | null,
};

export type CountryData = {
  __typename: "CountryData",
  id?: number | null,
  name?: string | null,
};

export type VoluntaryUpdateResponse = {
  __typename: "VoluntaryUpdateResponse",
  status?: number | null,
  currentStudiesStatusId?: number | null,
  currentStudiesStatusAlt?: string | null,
  currentStudiesOccupationId?: number | null,
  currentStudiesOccupationAlt?: string | null,
  afterStudiesStatusId?: number | null,
  afterStudiesStatusAlt?: string | null,
  afterStudiesOccupationId?: number | null,
  afterStudiesOccupationAlt?: string | null,
  afterStudiesStudyDegreeId?: number | null,
};

export type RepaymentsResponse = {
  __typename: "RepaymentsResponse",
  paid?:  Array<RepaymentItem > | null,
};

export type RepaymentItem = {
  __typename: "RepaymentItem",
  date?: string | null,
  amount?: number | null,
  description?: string | null,
};

export type GetCustomerPortalMediaLibraryFavoriteArticlesResponse = {
  __typename: "GetCustomerPortalMediaLibraryFavoriteArticlesResponse",
  articles:  Array<CustomerPortalMediaLibraryFavoriteArticle >,
};

export type CustomerPortalMediaLibraryFavoriteArticle = {
  __typename: "CustomerPortalMediaLibraryFavoriteArticle",
  id: number,
  userId: number,
  contentfulArticleId: string,
};

export type GetCustomerPortalMediaLibraryWatchedArticlesResponse = {
  __typename: "GetCustomerPortalMediaLibraryWatchedArticlesResponse",
  articles:  Array<CustomerPortalMediaLibraryWatchedArticle >,
};

export type CustomerPortalMediaLibraryWatchedArticle = {
  __typename: "CustomerPortalMediaLibraryWatchedArticle",
  id: number,
  userId: number,
  contentfulArticleId: string,
};

export type GetCustomerPortalMediaLibraryArticlesStatusByUserResponse = {
  __typename: "GetCustomerPortalMediaLibraryArticlesStatusByUserResponse",
  articles:  Array<CustomerPortalMediaLibraryArticleWithStatus >,
};

export type CustomerPortalMediaLibraryArticleWithStatus = {
  __typename: "CustomerPortalMediaLibraryArticleWithStatus",
  userId: number,
  contentfulArticleId: string,
  watched: boolean,
  watchTime?: number | null,
  favorite: boolean,
};

export type TravelExpensesRefundFormResponse = {
  __typename: "TravelExpensesRefundFormResponse",
  proposals:  Array<TravelExpensesRefunds >,
};

export type TravelExpensesRefunds = {
  __typename: "TravelExpensesRefunds",
  ID: number,
  IdEvent: string,
  formData?: string | null,
  expirationDate: string,
};

export type OfferData = {
  __typename: "OfferData",
  repaymentDuration?: number | null,
  repaymentBegin?: string | null,
  salaryShare?: number | null,
  status?: number | null,
  signingInProgress?: number | null,
  minMonthlySalary?: number | null,
  cappingLimit?: number | null,
  minMoneyMultiple?: number | null,
};

export type GetContractDetailsResponse = {
  __typename: "GetContractDetailsResponse",
  repaymentDuration: number,
  repaymentBegin: string,
  dateSigned: string,
  incomePart: number,
  cappingLimitAmountOriginal: number,
  minimumContractStartIncome: number,
  originalContract?: ContractData | null,
  changedContracts?:  Array<ContractData | null > | null,
};

export type TimeBufferTypeData = {
  __typename: "TimeBufferTypeData",
  name: string,
  maxDuration: string,
};

export type TimeBufferRequestData = {
  __typename: "TimeBufferRequestData",
  id: number,
  status: string,
  startDate: string,
  endDate: string,
  positions?:  Array<TimeBufferRequestPositionData > | null,
};

export type TimeBufferRequestPositionData = {
  __typename: "TimeBufferRequestPositionData",
  startDate: string,
  endDate: string,
  type: string,
};

export type CreateUserDataMutationVariables = {
  data: UserDataInput,
};

export type CreateUserDataMutation = {
  createUserData?:  {
    __typename: "UserDataCreateResponse",
    id: number,
  } | null,
};

export type UpdateUserDataMutationVariables = {
  data: UpdateUserDataInput,
};

export type UpdateUserDataMutation = {
  updateUserData?:  {
    __typename: "OkCognitoResponse",
    ok: boolean,
  } | null,
};

export type SaveAbroadDataMutationVariables = {
  data: UserAbroadDataInput,
};

export type SaveAbroadDataMutation = {
  saveAbroadData?:  {
    __typename: "AbroadDataSaveResponse",
    id: number,
  } | null,
};

export type SaveAbroadDataChangeMutationVariables = {
  data: UserAbroadDataInput,
};

export type SaveAbroadDataChangeMutation = {
  saveAbroadDataChange?:  {
    __typename: "AbroadDataSaveResponse",
    id: number,
  } | null,
};

export type ChangeDataMutationVariables = {
  data: UserChangeDataInput,
};

export type ChangeDataMutation = {
  changeData?:  {
    __typename: "UserChangeInputResponse",
    id: number,
  } | null,
};

export type ChangeContactDataMutationVariables = {
  data: UserChangeContactDataInput,
};

export type ChangeContactDataMutation = {
  changeContactData?:  {
    __typename: "GenericResultResponse",
    id: number,
  } | null,
};

export type ChangeEmailConfirmMutationVariables = {
  data: UserChangeEmailConfirmInput,
};

export type ChangeEmailConfirmMutation = {
  changeEmailConfirm?:  {
    __typename: "GenericResultResponse",
    id: number,
  } | null,
};

export type SaveFinancialDataMutationVariables = {
  data: UserFinancialDataInput,
};

export type SaveFinancialDataMutation = {
  saveFinancialData?:  {
    __typename: "FinancialDataSaveResponse",
    id: number,
  } | null,
};

export type CreateFinanceRequirementMutationVariables = {
  data?: FinanceRequirementPackageInput | null,
  items: Array< CreateFinanceRequirementItemInput | null >,
};

export type CreateFinanceRequirementMutation = {
  createFinanceRequirement?:  {
    __typename: "CreateFinanceRequirementResponse",
    id: number,
  } | null,
};

export type DeleteFinanceRequirementMutationVariables = {
};

export type DeleteFinanceRequirementMutation = {
  deleteFinanceRequirement?:  {
    __typename: "GenericResultResponse",
    id: number,
  } | null,
};

export type UpdateFinanceRequirementMutationVariables = {
  data?: FinanceRequirementPackageInput | null,
  items: Array< UpdateFinanceRequirementItemInput | null >,
};

export type UpdateFinanceRequirementMutation = {
  updateFinanceRequirement?:  {
    __typename: "UpdateFinanceRequirementResponse",
    id: number,
  } | null,
};

export type CreateDocumentMutationVariables = {
  document?: CreateDocumentInput | null,
};

export type CreateDocumentMutation = {
  createDocument?:  {
    __typename: "CreateDocumentsResponse",
    id?: number | null,
  } | null,
};

export type MarkDocumentAsSubmittedMutationVariables = {
  documentIds: Array< number >,
};

export type MarkDocumentAsSubmittedMutation = {
  markDocumentAsSubmitted?:  {
    __typename: "OkCognitoResponse",
    ok: boolean,
  } | null,
};

export type RemoveDocumentMutationVariables = {
  documentId?: number | null,
};

export type RemoveDocumentMutation = {
  removeDocument?:  {
    __typename: "RemoveDocumentsResponse",
    id?: number | null,
  } | null,
};

export type ApplicationSubmittedMutationVariables = {
  data: ApplicationSubmittedInput,
};

export type ApplicationSubmittedMutation = {
  applicationSubmitted?:  {
    __typename: "ApplicationSubmittedResponse",
    id: number,
  } | null,
};

export type InitVerificationMutationVariables = {
  data?: InitVerificationInput | null,
};

export type InitVerificationMutation = {
  initVerification?:  {
    __typename: "InitVerificationResponse",
    id: string,
    url?: string | null,
  } | null,
};

export type RejectOfferMutationVariables = {
  data: Array< FormDataContentInput >,
};

export type RejectOfferMutation = {
  rejectOffer:  {
    __typename: "RejectOfferResponse",
    id: number,
  },
};

export type UpdateCognitoIdentityMutationVariables = {
  data?: CognitoIdentityDataInput | null,
};

export type UpdateCognitoIdentityMutation = {
  updateCognitoIdentity?:  {
    __typename: "UpdateCognitoIdentityResponse",
    id: number,
  } | null,
};

export type SaveVoluntaryUpdateMutationVariables = {
  data?: VoluntaryUpdateInput | null,
};

export type SaveVoluntaryUpdateMutation = {
  saveVoluntaryUpdate?:  {
    __typename: "GenericResultResponse",
    id: number,
  } | null,
};

export type ChangeVoluntaryUpdateStatusMutationVariables = {
  data?: VoluntaryUpdateStatusInput | null,
};

export type ChangeVoluntaryUpdateStatusMutation = {
  changeVoluntaryUpdateStatus?:  {
    __typename: "GenericResultResponse",
    id: number,
  } | null,
};

export type UserChangeContractMutationVariables = {
  data?: UserChangeContractInput | null,
};

export type UserChangeContractMutation = {
  // Full change contract endpoint
  userChangeContract?:  {
    __typename: "UserChangeContractResponse",
    id: number,
  } | null,
};

export type InitChangeContractMutationVariables = {
};

export type InitChangeContractMutation = {
  // Initialize change contract
  initChangeContract?:  {
    __typename: "GenericResultResponse",
    id: number,
  } | null,
};

export type UserCancelChangeContractMutationVariables = {
};

export type UserCancelChangeContractMutation = {
  // Cancel change contract
  userCancelChangeContract?:  {
    __typename: "UserChangeContractResponse",
    id: number,
  } | null,
};

export type UserSetChangeContractStatusMutationVariables = {
  newStatus: number,
};

export type UserSetChangeContractStatusMutation = {
  userSetChangeContractStatus?:  {
    __typename: "GenericResultResponse",
    id: number,
  } | null,
};

export type SaveSemesterUpdateMutationVariables = {
  data: SemesterUpdateInput,
};

export type SaveSemesterUpdateMutation = {
  saveSemesterUpdate?:  {
    __typename: "GenericResultResponse",
    id: number,
  } | null,
};

export type UpdateClientEmailMutationVariables = {
  data?: UpdateClientEmailInput | null,
};

export type UpdateClientEmailMutation = {
  // Requires an additional header for authentication `x-gema-secret`
  updateClientEmail?:  {
    __typename: "UpdateClientEmailResponse",
    id: number,
  } | null,
};

export type UpdateOfferStatusMutationVariables = {
  data?: UpdateOfferStatusInput | null,
};

export type UpdateOfferStatusMutation = {
  // Requires an additional header for authentication `x-gema-secret`
  updateOfferStatus?:  {
    __typename: "UpdateOfferStatusResponse",
    id: number,
  } | null,
};

export type UpdateStatusMutationVariables = {
  data?: UpdateStatusInput | null,
};

export type UpdateStatusMutation = {
  // Requires an additional header for authentication `x-gema-secret`
  updateStatus?:  {
    __typename: "OkResponse",
    ok: boolean,
  } | null,
};

export type DeleteUserMutationVariables = {
  data?: DeleteUserInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "DeleteUserResponse",
    id: number,
  } | null,
};

export type RejectChangeOfferMutationVariables = {
};

export type RejectChangeOfferMutation = {
  rejectChangeOffer?:  {
    __typename: "RejectChangeOfferResponse",
    id?: number | null,
  } | null,
};

export type UpdateChangeOfferStatusMutationVariables = {
  data?: UpdateChangeOfferStatusInput | null,
};

export type UpdateChangeOfferStatusMutation = {
  // Requires an additional header for authentication `x-gema-secret`
  updateChangeOfferStatus?:  {
    __typename: "UpdateChangeOfferStatusResponse",
    id: number,
  } | null,
};

export type NotifyContactChangedMutationVariables = {
  clientId: number,
};

export type NotifyContactChangedMutation = {
  // Requires an additional header for authentication `x-gema-secret`
  // Called when Gema updates user details that need to be sent to custiomer.io
  notifyContactChanged?:  {
    __typename: "NotifyContactChangedResponse",
    id: number,
  } | null,
};

export type CreateOfferMutationVariables = {
  data?: CreateOfferInput | null,
};

export type CreateOfferMutation = {
  // for mocking purpose only
  createOffer?:  {
    __typename: "CreateOfferResponse",
    id: number,
  } | null,
};

export type SyncSemesterUpdateMutationVariables = {
};

export type SyncSemesterUpdateMutation = {
  syncSemesterUpdate?:  {
    __typename: "SyncSemesterUpdateResponse",
    count?: number | null,
  } | null,
};

export type TestOfferSignedMutationVariables = {
  data?: TestOfferSignedInput | null,
};

export type TestOfferSignedMutation = {
  // for mocking purpose only
  testOfferSigned?:  {
    __typename: "TestOfferSignedResponse",
    id: number,
  } | null,
};

export type MockOfferSignedMutationVariables = {
  data?: MockOfferSignedInput | null,
};

export type MockOfferSignedMutation = {
  // for mocking purpose only
  mockOfferSigned?:  {
    __typename: "MockOfferSignedResponse",
    id: number,
  } | null,
};

export type AdditionalDocumentsMutationVariables = {
  documents: Array< AdditionalDocumentInput | null >,
};

export type AdditionalDocumentsMutation = {
  additionalDocuments?:  {
    __typename: "AdditionalDocumentsResponse",
    ids: Array< number | null >,
  } | null,
};

export type SemesterUpdateDocumentsMutationVariables = {
  documents: Array< AdditionalDocumentInput | null >,
  semesterUpdateId: number,
};

export type SemesterUpdateDocumentsMutation = {
  semesterUpdateDocuments?:  {
    __typename: "AdditionalDocumentsResponse",
    ids: Array< number | null >,
  } | null,
};

export type MockRequireSemesterUpdateMutationVariables = {
  data: MockRequireSemesterUpdateInput,
};

export type MockRequireSemesterUpdateMutation = {
  // mock require semesterUpdate
  mockRequireSemesterUpdate?:  {
    __typename: "MockRequireSemesterUpdateResponse",
    id: number,
  } | null,
};

export type CustomerIoUnsubscribeMutationVariables = {
  data: CustomerIoUnsubscribeInput,
};

export type CustomerIoUnsubscribeMutation = {
  // marks user as unsubscribed in customerio - won't receive any messages
  customerIoUnsubscribe?:  {
    __typename: "CustomerIoUnsubscribeResponse",
    id: number,
  } | null,
};

export type MockRequireYearUpdateMutationVariables = {
  data: MockRequireYearUpdateInput,
};

export type MockRequireYearUpdateMutation = {
  mockRequireYearUpdate?:  {
    __typename: "MockRequireYearUpdateResponse",
    id: number,
  } | null,
};

export type SaveYearUpdateMutationVariables = {
  data: YearUpdateInput,
};

export type SaveYearUpdateMutation = {
  saveYearUpdate?:  {
    __typename: "GenericResultResponse",
    id: number,
  } | null,
};

export type InitContactDataChangeMutationVariables = {
  data: InitContactDataChangeInput,
};

export type InitContactDataChangeMutation = {
  initContactDataChange?:  {
    __typename: "GenericResultResponse",
    id: number,
  } | null,
};

export type SendCustomFormMutationVariables = {
  data?: FormDataInput | null,
};

export type SendCustomFormMutation = {
  sendCustomForm?:  {
    __typename: "GenericSuccessResponse",
    success: boolean,
    message?: string | null,
  } | null,
};

export type CustomerPortalMediaLibraryAddFavoriteArticleMutationVariables = {
  data: customerPortalMediaLibraryAddFavoriteArticleInput,
};

export type CustomerPortalMediaLibraryAddFavoriteArticleMutation = {
  customerPortalMediaLibraryAddFavoriteArticle?:  {
    __typename: "GenericResultResponse",
    id: number,
  } | null,
};

export type CustomerPortalMediaLibraryAddWatchedArticleMutationVariables = {
  data: customerPortalMediaLibraryAddWatchedArticleInput,
};

export type CustomerPortalMediaLibraryAddWatchedArticleMutation = {
  customerPortalMediaLibraryAddWatchedArticle?:  {
    __typename: "GenericResultResponse",
    id: number,
  } | null,
};

export type CustomerPortalMediaLibraryDeleteFavoriteArticleMutationVariables = {
  data: customerPortalMediaLibraryDeleteFavoriteArticleInput,
};

export type CustomerPortalMediaLibraryDeleteFavoriteArticleMutation = {
  customerPortalMediaLibraryDeleteFavoriteArticle?:  {
    __typename: "GenericSuccessResponse",
    success: boolean,
    message?: string | null,
  } | null,
};

export type CustomerPortalMediaLibraryDeleteWatchedArticleMutationVariables = {
  data: customerPortalMediaLibraryDeleteWatchedArticleInput,
};

export type CustomerPortalMediaLibraryDeleteWatchedArticleMutation = {
  customerPortalMediaLibraryDeleteWatchedArticle?:  {
    __typename: "GenericSuccessResponse",
    success: boolean,
    message?: string | null,
  } | null,
};

export type CustomerPortalMediaLibraryUpdateWatchedArticleMutationVariables = {
  data: customerPortalMediaLibraryUpdateWatchedArticleInput,
};

export type CustomerPortalMediaLibraryUpdateWatchedArticleMutation = {
  customerPortalMediaLibraryUpdateWatchedArticle?:  {
    __typename: "GenericSuccessResponse",
    success: boolean,
    message?: string | null,
  } | null,
};

export type CreateTravelExpensesRefundProposalMutationVariables = {
  data: createTravelExpensesRefundProposalInput,
};

export type CreateTravelExpensesRefundProposalMutation = {
  createTravelExpensesRefundProposal?:  {
    __typename: "CreateTravelExpensesRefundProposalResponse",
    id: number,
    clientId: number,
    eventId: string,
    expirationDate: string,
  } | null,
};

export type UpdateTravelExpensesRefundMutationVariables = {
  data: updateTravelExpensesRefundInput,
};

export type UpdateTravelExpensesRefundMutation = {
  updateTravelExpensesRefund?:  {
    __typename: "GenericSuccessResponse",
    success: boolean,
    message?: string | null,
  } | null,
};

export type DeleteTravelExpensesRefundMutationVariables = {
  travelExpensesRefundId: number,
};

export type DeleteTravelExpensesRefundMutation = {
  deleteTravelExpensesRefund?:  {
    __typename: "GenericAnonymousSuccessResponse",
    success: boolean,
    message?: string | null,
  } | null,
};

export type AskForAdditionalDocumentsMutationVariables = {
  data: askForAdditionalDocumentsInput,
};

export type AskForAdditionalDocumentsMutation = {
  askForAdditionalDocuments?:  {
    __typename: "GenericAnonymousSuccessResponse",
    success: boolean,
    message?: string | null,
  } | null,
};

export type SaveAdBlockDetectionMutationVariables = {
  data: saveAdblockDetectionInput,
};

export type SaveAdBlockDetectionMutation = {
  saveAdBlockDetection?:  {
    __typename: "GenericSuccessResponse",
    success: boolean,
    message?: string | null,
  } | null,
};

export type ChangeOfferRequestContactMutationVariables = {
};

export type ChangeOfferRequestContactMutation = {
  changeOfferRequestContact?:  {
    __typename: "GenericSuccessResponse",
    success: boolean,
    message?: string | null,
  } | null,
};

export type AddContractVersionMutationVariables = {
  data: addContractVersionInput,
};

export type AddContractVersionMutation = {
  addContractVersion?:  {
    __typename: "AddContractVersionResponse",
    id: number,
  } | null,
};

export type UpdateContractVersionMutationVariables = {
  data: updateContractVersionInput,
};

export type UpdateContractVersionMutation = {
  updateContractVersion?:  {
    __typename: "UpdateContractVersionResponse",
    success: boolean,
  } | null,
};

export type DeleteContractVersionMutationVariables = {
  data: deleteContractVersionInput,
};

export type DeleteContractVersionMutation = {
  deleteContractVersion?:  {
    __typename: "DeleteContractVersionResponse",
    success: boolean,
  } | null,
};

export type CalculateTimeBufferCostMutationVariables = {
  data: calculateTimeBufferCostInput,
};

export type CalculateTimeBufferCostMutation = {
  calculateTimeBufferCost?:  {
    __typename: "CalculateTimeBufferCostResponse",
    totalRepaymentPeriodExtension: number,
  } | null,
};

export type _pulumi__authTestUtilsQueryVariables = {
};

export type _pulumi__authTestUtilsQuery = {
  _pulumi__authTestUtils?:  {
    __typename: "OkResponse",
    ok: boolean,
  } | null,
};

export type CityAutoCompleteQueryVariables = {
  query: string,
  limit?: number | null,
};

export type CityAutoCompleteQuery = {
  cityAutoComplete:  Array< {
    __typename: "CityAutocompleteResponse",
    id: string,
    value: string,
  } >,
};

export type HighSchoolAutoCompleteQueryVariables = {
  query: string,
  limit?: number | null,
};

export type HighSchoolAutoCompleteQuery = {
  highSchoolAutoComplete:  Array< {
    __typename: "HighSchoolAutocompleteResponse",
    id: number,
    value: string,
  } >,
};

export type SpecializationAutoCompleteQueryVariables = {
  query: string,
  limit?: number | null,
};

export type SpecializationAutoCompleteQuery = {
  specializationAutoComplete?:  Array< {
    __typename: "SpecializationAutocompleteResponse",
    id: number,
    value: string,
    subjectId?: number | null,
  } | null > | null,
};

export type MostPopularSpecializationsQueryVariables = {
  ids: Array< number | null >,
};

export type MostPopularSpecializationsQuery = {
  mostPopularSpecializations?:  Array< {
    __typename: "MostPopularSpecializationsResponse",
    id: number,
    value: string,
    subjectId: number,
  } | null > | null,
};

export type SubjectAutoCompleteQueryVariables = {
  query: string,
  limit?: number | null,
};

export type SubjectAutoCompleteQuery = {
  subjectAutoComplete?:  Array< {
    __typename: "SubjectAutocompleteResponse",
    id: number,
    value: string,
  } | null > | null,
};

export type CountryAutoCompleteQueryVariables = {
  query: string,
  limit?: number | null,
};

export type CountryAutoCompleteQuery = {
  countryAutoComplete?:  Array< {
    __typename: "CountryAutocompleteResponse",
    id: string,
    value: string,
  } | null > | null,
};

export type FoundingReasonAutoCompleteQueryVariables = {
  query: string,
  limit?: number | null,
};

export type FoundingReasonAutoCompleteQuery = {
  foundingReasonAutoComplete?:  Array< {
    __typename: "GenericAutocompleteResponse",
    id: string,
    value: string,
  } | null > | null,
};

export type NationalityAutoCompleteQueryVariables = {
  query: string,
  limit?: number | null,
};

export type NationalityAutoCompleteQuery = {
  nationalityAutoComplete?:  Array< {
    __typename: "NationalityAutocompleteResponse",
    id: string,
    value: string,
    isEuropean: number,
  } | null > | null,
};

export type GetMaximumLoanQueryVariables = {
  majorId: number,
  graduationId: number,
};

export type GetMaximumLoanQuery = {
  getMaximumLoan?: number | null,
};

export type GetLoanLimitQueryVariables = {
  majorId?: number | null,
  fieldId?: number | null,
  graduationId: number,
};

export type GetLoanLimitQuery = {
  getLoanLimit:  {
    __typename: "LoanLimit",
    minLoan?: number | null,
    maxLoan?: number | null,
  },
};

export type AutomatedFeedbackQueryVariables = {
  data: AutomatedFeedbackInput,
};

export type AutomatedFeedbackQuery = {
  automatedFeedback?:  {
    __typename: "AutomatedFeedbackResponse",
    majorName?: string | null,
    majorId?: number | null,
    subjectId?: number | null,
    degreeId?: number | null,
    degreeTypeId?: number | null,
    variantId?: number | null,
    text: string,
    allowToProceed: boolean,
  } | null,
};

export type GetCurrenciesQueryVariables = {
};

export type GetCurrenciesQuery = {
  getCurrencies?:  {
    __typename: "GetCurrenciesResponse",
    currencies:  Array< {
      __typename: "Currency",
      id: number,
      code: string,
      name: string,
      deleted: boolean,
    } >,
  } | null,
};

export type GetIdForQueryVariables = {
  input: GetIdForInput,
};

export type GetIdForQuery = {
  // Requires an additional header for authentication `x-test-secret`
  getIdFor?:  {
    __typename: "GetIdForResponse",
    id: number,
  } | null,
};

export type SetClientStatusQueryVariables = {
  clientId: number,
  status: number,
};

export type SetClientStatusQuery = {
  // Requires an additional header for authentication `x-test-secret`
  setClientStatus?:  {
    __typename: "OkResponse",
    ok: boolean,
  } | null,
};

export type GetDegreesByIdsQueryVariables = {
  ids: Array< number | null >,
};

export type GetDegreesByIdsQuery = {
  getDegreesByIds?:  Array< {
    __typename: "GetDegreesByIdsResponse",
    id: number,
    value: string,
  } | null > | null,
};

export type DisabledServicePortalQueryVariables = {
  email: string,
};

export type DisabledServicePortalQuery = {
  disabledServicePortal?:  {
    __typename: "DisabledServicePortalResponse",
    disableServicePortal: boolean,
  } | null,
};

export type EmailNotExistInCognitoQueryVariables = {
  email: string,
};

export type EmailNotExistInCognitoQuery = {
  emailNotExistInCognito?:  {
    __typename: "EmailNotExistInCognitoResponse",
    exist: boolean,
  } | null,
};

export type GetContractVersionForContentfulQueryVariables = {
  cognitoUsername: string,
};

export type GetContractVersionForContentfulQuery = {
  getContractVersionForContentful?:  {
    __typename: "GetContractVersionForContentfulResponse",
    version: string,
  } | null,
};

export type _pulumi__authGemaQueryVariables = {
};

export type _pulumi__authGemaQuery = {
  // "_pulumi__" prefix are placeholder to handle function mapping in pulumi
  _pulumi__authGema?:  {
    __typename: "OkResponse",
    ok: boolean,
  } | null,
};

export type _pulumi__authCognitoQueryVariables = {
};

export type _pulumi__authCognitoQuery = {
  _pulumi__authCognito?:  {
    __typename: "OkResponse",
    ok: boolean,
  } | null,
};

export type GetPendingSemesterUpdatesQueryVariables = {
  semesterUpdateId?: number | null,
};

export type GetPendingSemesterUpdatesQuery = {
  getPendingSemesterUpdates:  Array< {
    __typename: "PendingSemesterUpdate",
    id: number,
    semesterType: number,
    status?: number | null,
    semesterYear: number,
    studiesEnd: string,
  } >,
};

export type GetNewCookieQueryVariables = {
};

export type GetNewCookieQuery = {
  getNewCookie?:  {
    __typename: "NewCookieResponse",
    encodedCookiePayload: string,
  } | null,
};

export type GetContractQueryVariables = {
  statusId: number,
};

export type GetContractQuery = {
  getContract?:  {
    __typename: "ContractData",
    id?: number | null,
    filename?: string | null,
    dateSigned?: string | null,
    dateCreated?: string | null,
  } | null,
};

export type ValidateIbanQueryVariables = {
  iban: string,
};

export type ValidateIbanQuery = {
  validateIban:  {
    __typename: "IbanValidationaResults",
    isValid: boolean,
    bankName?: string | null,
    bic?: string | null,
    bankCity?: string | null,
  },
};

export type GetContractVersionQueryVariables = {
  id: number,
};

export type GetContractVersionQuery = {
  getContractVersion?:  {
    __typename: "GetContractVersionResponse",
    id: number,
    contractType: number,
    contractVariant: number,
    contractVersion: string,
    nameOfContractVersion: string,
    validFrom?: string | null,
    validUntil?: string | null,
    comment?: string | null,
    ruleset?: string | null,
  } | null,
};

export type CheckTimeBufferEligibilityQueryVariables = {
};

export type CheckTimeBufferEligibilityQuery = {
  // Check if authenticated customer is eligible for time buffer
  checkTimeBufferEligibility?:  {
    __typename: "TimeBufferEligibility",
    isEligible: boolean,
    isBeforeRepaymentStartDate?: boolean | null,
  } | null,
};

export type CalculateCreditScoreQueryVariables = {
  data?: CalculateCreditScoreInput | null,
};

export type CalculateCreditScoreQuery = {
  // Requires an additional header for authentication `x-gema-secret`
  calculateCreditScore?:  {
    __typename: "CalculateCreditScoreResponse",
    errorMessage?: string | null,
    scoreValue?: string | null,
    riskRatio?: string | null,
    scoreRange?: string | null,
    scoreText?: string | null,
    scoreInfoText?: Array< string > | null,
    birthDate?: string | null,
    birthPlace?: string | null,
    currentAddress?:  {
      __typename: "CalculateCreditScoreAddress",
      street?: string | null,
      zipCode?: number | null,
      city?: string | null,
      country?: string | null,
    } | null,
    previousAddress?:  {
      __typename: "CalculateCreditScoreAddress",
      street?: string | null,
      zipCode?: number | null,
      city?: string | null,
      country?: string | null,
    } | null,
  } | null,
};

export type CalculatePricingQueryVariables = {
  data: CalculatePricingInput,
};

export type CalculatePricingQuery = {
  // Requires an additional header for authentication `x-gema-secret`
  calculatePricing?:  {
    __typename: "CalculatePricingResponse",
    income_part: number,
    repayment_period: number,
    contract_length: number,
    total_costs: number,
    over_under_founding: number,
    money_multiple: number,
    capping_limit: number,
    salary_share: number,
    annual_interest: number,
    estimated_income: number,
    contract_income: number,
    initial_income: number,
    inflation_rate: number,
  } | null,
};

export type CalculateExpectedSalaryQueryVariables = {
  data?: CalculateExpectedSalaryInput | null,
};

export type CalculateExpectedSalaryQuery = {
  // Requires an additional header for authentication `x-gema-secret`
  calculateExpectedSalary?:  {
    __typename: "CalculateExpectedSalaryResponse",
    salary: number,
    initialSalary: number,
    inflationRate: number,
    months: number,
  } | null,
};

export type GetUserDataQueryVariables = {
};

export type GetUserDataQuery = {
  getUserData?:  {
    __typename: "UserData",
    id?: number | null,
    status?: number | null,
    salutationId?: number | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    englishLevel?: number | null,
    birthDate?: string | null,
    startDate?: string | null,
    endDate?: string | null,
    contractDate?: string | null,
    cognitoUsername?: string | null,
    cognitoIdentity?: string | null,
    schoolNote?: number | null,
    variantId?: number | null,
    expectedDegreeId?: number | null,
    previousEducation?:  {
      __typename: "PreviousEducationData",
      id?: number | null,
      name?: string | null,
    } | null,
    highSchool?:  {
      __typename: "HighSchoolData",
      id?: number | null,
      name?: string | null,
      city?: string | null,
    } | null,
    major?:  {
      __typename: "MajorData",
      id?: number | null,
      name?: string | null,
      subjectId?: number | null,
    } | null,
    field?:  {
      __typename: "FieldData",
      id?: number | null,
      name?: string | null,
    } | null,
    financingPackage?:  {
      __typename: "FinancingPackageData",
      id?: number | null,
      dateFrom?: string | null,
      dateTo?: string | null,
      amountTotal?: number | null,
    } | null,
    financingPackageChange?:  {
      __typename: "FinancingPackageData",
      id?: number | null,
      dateFrom?: string | null,
      dateTo?: string | null,
      amountTotal?: number | null,
    } | null,
    documents?:  Array< {
      __typename: "DocumentData",
      id: number,
      name: string,
      key?: string | null,
      field: string,
      changeDataId?: number | null,
    } | null > | null,
    consultant?:  {
      __typename: "ConsultantData",
      id?: number | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      phone?: string | null,
      imageUrl?: string | null,
    } | null,
    contract?:  {
      __typename: "ContractData",
      id?: number | null,
      filename?: string | null,
      dateSigned?: string | null,
      dateCreated?: string | null,
    } | null,
    additionalDocuments?:  Array< {
      __typename: "AdditionalDocumentsData",
      ID: number,
      documentName: string,
      description?: string | null,
      allowMultiple?: boolean | null,
      semesterUpdateId?: number | null,
      documentTypeId: number,
      DocumentAlt?: string | null,
    } > | null,
    loanLimit?:  {
      __typename: "UserLoanLimit",
      minLoan?: number | null,
      maxLoan?: number | null,
    } | null,
    personalInfosStepRejectedStatus?: number | null,
    changeOfferStatus?: number | null,
    expectedDegree?:  {
      __typename: "DegreeData",
      id?: number | null,
      name?: string | null,
    } | null,
    campaignToken?: string | null,
    customerCareStatus?: number | null,
    yearUpdate:  Array< {
      __typename: "YearUpdateData",
      year: number,
      IdStatus?: number | null,
      wasEmployed: boolean,
      wasFreelancer: boolean,
      wasUnemployed: boolean,
      wasStudying: boolean,
      hadParentalLeave: boolean,
      numberOfJobs?: number | null,
    } >,
    nationalityId?: number | null,
    nationality?:  {
      __typename: "NationaliotyData",
      id?: number | null,
      name?: string | null,
      isEuropean?: number | null,
    } | null,
    birthCountryId?: number | null,
    birthCountry?:  {
      __typename: "CountryData",
      id?: number | null,
      name?: string | null,
    } | null,
    birthPlace?: string | null,
    childrenCount?: number | null,
    street?: string | null,
    homeNumber?: string | null,
    additionalAddress?: string | null,
    zip?: string | null,
    city?: string | null,
    countryId?: number | null,
    country?:  {
      __typename: "CountryData",
      id?: number | null,
      name?: string | null,
    } | null,
    phone?: string | null,
    residenceStatus?: number | null,
    currentSemesterNumber?: number | null,
    remainingSemesterNumber?: number | null,
    ectsPoints?: number | null,
    ectsPointsTotal?: number | null,
    averageNote?: number | null,
    iban?: string | null,
    bank?: string | null,
    bic?: string | null,
    previousStudies?: boolean | null,
    previousStudiesDegree?:  {
      __typename: "DegreeData",
      id?: number | null,
      name?: string | null,
    } | null,
    previousStudiesGrade?: number | null,
    universityId?: number | null,
    universityAlt?: string | null,
    restrictedStay?: string | null,
  } | null,
};

export type GetPendingPackageQueryVariables = {
};

export type GetPendingPackageQuery = {
  getPendingPackage?:  {
    __typename: "FinancingPackageData",
    id?: number | null,
    dateFrom?: string | null,
    dateTo?: string | null,
    amountTotal?: number | null,
    items?:  Array< {
      __typename: "FinancingPackageItem",
      id?: number | null,
      type?: FinancingPackageItemType | null,
      dateFrom?: string | null,
      dateTo?: string | null,
      paymentRhythmId?: number | null,
      numberOfPayments?: number | null,
      amount?: number | null,
      amountTotal?: number | null,
      moneyUsage?: string | null,
      isChangeRequest?: boolean | null,
      status?: number | null,
    } | null > | null,
    changeData?:  {
      __typename: "FinancingPackageChangeData",
      id?: number | null,
      startStudies?: string | null,
      endStudies?: string | null,
    } | null,
  } | null,
};

export type GetPendingVoluntaryUpdateQueryVariables = {
};

export type GetPendingVoluntaryUpdateQuery = {
  getPendingVoluntaryUpdate?:  {
    __typename: "VoluntaryUpdateResponse",
    status?: number | null,
    currentStudiesStatusId?: number | null,
    currentStudiesStatusAlt?: string | null,
    currentStudiesOccupationId?: number | null,
    currentStudiesOccupationAlt?: string | null,
    afterStudiesStatusId?: number | null,
    afterStudiesStatusAlt?: string | null,
    afterStudiesOccupationId?: number | null,
    afterStudiesOccupationAlt?: string | null,
    afterStudiesStudyDegreeId?: number | null,
  } | null,
};

export type GetContractRepaymentsQueryVariables = {
};

export type GetContractRepaymentsQuery = {
  getContractRepayments?:  {
    __typename: "RepaymentsResponse",
    paid?:  Array< {
      __typename: "RepaymentItem",
      date?: string | null,
      amount?: number | null,
      description?: string | null,
    } > | null,
  } | null,
};

export type GetCustomerPortalMediaLibraryFavoriteArticlesQueryVariables = {
};

export type GetCustomerPortalMediaLibraryFavoriteArticlesQuery = {
  getCustomerPortalMediaLibraryFavoriteArticles?:  {
    __typename: "GetCustomerPortalMediaLibraryFavoriteArticlesResponse",
    articles:  Array< {
      __typename: "CustomerPortalMediaLibraryFavoriteArticle",
      id: number,
      userId: number,
      contentfulArticleId: string,
    } >,
  } | null,
};

export type GetCustomerPortalMediaLibraryWatchedArticlesQueryVariables = {
};

export type GetCustomerPortalMediaLibraryWatchedArticlesQuery = {
  getCustomerPortalMediaLibraryWatchedArticles?:  {
    __typename: "GetCustomerPortalMediaLibraryWatchedArticlesResponse",
    articles:  Array< {
      __typename: "CustomerPortalMediaLibraryWatchedArticle",
      id: number,
      userId: number,
      contentfulArticleId: string,
    } >,
  } | null,
};

export type GetCustomerPortalMediaLibraryArticlesStatusByUserQueryVariables = {
};

export type GetCustomerPortalMediaLibraryArticlesStatusByUserQuery = {
  getCustomerPortalMediaLibraryArticlesStatusByUser?:  {
    __typename: "GetCustomerPortalMediaLibraryArticlesStatusByUserResponse",
    articles:  Array< {
      __typename: "CustomerPortalMediaLibraryArticleWithStatus",
      userId: number,
      contentfulArticleId: string,
      watched: boolean,
      watchTime?: number | null,
      favorite: boolean,
    } >,
  } | null,
};

export type GetTravelExpensesRefundFormsQueryVariables = {
  showFilled?: boolean | null,
};

export type GetTravelExpensesRefundFormsQuery = {
  getTravelExpensesRefundForms:  {
    __typename: "TravelExpensesRefundFormResponse",
    proposals:  Array< {
      __typename: "TravelExpensesRefunds",
      ID: number,
      IdEvent: string,
      formData?: string | null,
      expirationDate: string,
    } >,
  },
};

export type GetOfferConditionQueryVariables = {
};

export type GetOfferConditionQuery = {
  getOfferCondition?:  {
    __typename: "OfferData",
    repaymentDuration?: number | null,
    repaymentBegin?: string | null,
    salaryShare?: number | null,
    status?: number | null,
    signingInProgress?: number | null,
    minMonthlySalary?: number | null,
    cappingLimit?: number | null,
    minMoneyMultiple?: number | null,
  } | null,
};

export type GetContractDetailsQueryVariables = {
};

export type GetContractDetailsQuery = {
  getContractDetails?:  {
    __typename: "GetContractDetailsResponse",
    repaymentDuration: number,
    repaymentBegin: string,
    dateSigned: string,
    incomePart: number,
    cappingLimitAmountOriginal: number,
    minimumContractStartIncome: number,
    originalContract?:  {
      __typename: "ContractData",
      id?: number | null,
      filename?: string | null,
      dateSigned?: string | null,
      dateCreated?: string | null,
    } | null,
    changedContracts?:  Array< {
      __typename: "ContractData",
      id?: number | null,
      filename?: string | null,
      dateSigned?: string | null,
      dateCreated?: string | null,
    } | null > | null,
  } | null,
};

export type GetTimeBufferTypeQueryVariables = {
  timeBufferName: string,
};

export type GetTimeBufferTypeQuery = {
  getTimeBufferType?:  {
    __typename: "TimeBufferTypeData",
    name: string,
    maxDuration: string,
  } | null,
};

export type GetTimeBufferRequestsQueryVariables = {
  statuses?: Array< string > | null,
};

export type GetTimeBufferRequestsQuery = {
  getTimeBufferRequests?:  Array< {
    __typename: "TimeBufferRequestData",
    id: number,
    status: string,
    startDate: string,
    endDate: string,
    positions?:  Array< {
      __typename: "TimeBufferRequestPositionData",
      startDate: string,
      endDate: string,
      type: string,
    } > | null,
  } > | null,
};
