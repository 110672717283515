import type { InstallmentEntry } from '../../../../containers/YourFinancingPage/useYourFinancingPage';

import React from 'react';

const formatInstallmentEntryDescription = (item: InstallmentEntry) => {
  switch (item.type) {
    case 'MONTHLY': {
      return (
        <span>
          {item.numberOfInstallment} von {item.totalNumberOfInstallment}{' '}
          monatlichen Zahlungen
        </span>
      );
    }
    case 'ONE_TIME': {
      return <span>Einmalige Zahlung</span>;
    }
    case 'HALF_YEARLY': {
      return (
        <span>
          {item.numberOfInstallment} von {item.totalNumberOfInstallment}{' '}
          halbjährlich Zahlungen
        </span>
      );
    }
    case 'YEARLY': {
      return (
        <span>
          {item.numberOfInstallment} von {item.totalNumberOfInstallment}{' '}
          jährlich Zahlungen`
        </span>
      );
    }
    default: {
      return '';
    }
  }
};

export default formatInstallmentEntryDescription;
