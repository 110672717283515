import styled from 'styled-components';

import OfferSection from '../../elements/OfferSection';

import theme from '$/assets/theme';

const HomePage = styled('div')`
  @media (min-width: ${theme.breakpoints.lg}) {
    margin-top: 24px;
  }
`;

type PageProps = typeof HomePage & {
  OfferSection: typeof OfferSection;
};

const Page = HomePage as PageProps;

Page.OfferSection = OfferSection;

export default Page;
