import styled from 'styled-components';

import { PTypography } from '../../elements/Typography';

const ButtonDescriptionText = styled(PTypography)`
  color: ${(props) => props.theme.colors.grey5};
  font-size: 15px;
  letter-spacing: 0.12px;
`;

export default ButtonDescriptionText;
