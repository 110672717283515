import type { PendingSemesterUpdate } from '$/API';
import type { TDisplayInstallmentItem } from '$/components/elements/InstallmentTable';
import type { ICurrentUserValues } from '$/hooks/useCurrentUser';
import type { IHomePageProps } from '$/pages';
import type { TEventsGetEventsResponse } from '$/types/api/events';
import type { ICustomerPortalEvent } from '$/types/contentful';
import type { LDFlagSet } from 'launchdarkly-js-sdk-common';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import ApiClient from '$/ApiClient';
import { financingPackageItemToDisplayInstallmentItem } from '$/components/elements/InstallmentTable';
import useCurrentUser from '$/hooks/useCurrentUser';

export interface IHomeStateProps {
  flags: LDFlagSet;
  userData: ICurrentUserValues['userData'];
  payments: TDisplayInstallmentItem[];
  changeOfferInProgress: boolean;

  pendingSemesterUpdates: PendingSemesterUpdate[];
  yearlyUpdatePending: ICurrentUserValues['yearlyUpdatePending'];

  pendingTravelExpensesForm: ICustomerPortalEvent[] | null;
}

const useHomePage = ({}: IHomePageProps): IHomeStateProps => {
  const flags = useFlags();
  const { userData, yearlyUpdatePending } = useCurrentUser();

  const pendingSemesterUpdatesQuery = useQuery<PendingSemesterUpdate[]>(
    ['pending-semester-updates'],
    async () => ApiClient.getPendingSemesterUpdates(),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const travelExpensesQuery = useQuery<TEventsGetEventsResponse>(
    ['travel-expenses'],
    async () => {
      const travelExpensesRefundForms =
        await ApiClient.getTravelExpensesRefundForms({ showFilled: false });
      const eventsId =
        travelExpensesRefundForms?.getTravelExpensesRefundForms?.proposals?.map(
          (proposal) => proposal.IdEvent
        ) ?? [];

      if (eventsId.length > 0) {
        return (
          await ApiClient.getEventsById({
            eventsId: eventsId,
          })
        ).data;
      } else {
        return { articles: [], numberOfElements: 0 };
      }
    },
    { refetchOnWindowFocus: false }
  );

  const payments = useMemo(() => {
    return (
      userData?.financingPackage?.items
        ?.filter((item) => item?.status === 70)
        .map(financingPackageItemToDisplayInstallmentItem) ?? []
    );
  }, [userData?.financingPackage?.items]);

  const pendingTravelExpensesForm = useMemo(() => {
    if (
      !travelExpensesQuery.data ||
      travelExpensesQuery.data.numberOfElements === 0
    ) {
      return null;
    }
    return travelExpensesQuery.data.articles;
  }, [travelExpensesQuery.data]);

  const changeOfferInProgress = useMemo(() => {
    return [1080, 1081, 1082].includes(userData?.changeOfferStatus as number);
  }, [userData?.changeOfferStatus]);

  // const yearlyUpdateMissingDocuments = useMemo(() => {
  //   const documents = userData?.documents
  //   if (!documents) {
  //     return null
  //   }
  //   // TODO: With Continue later
  // }, [userData?.yearUpdate])

  return {
    flags,
    userData,
    payments,
    changeOfferInProgress,
    yearlyUpdatePending,
    pendingTravelExpensesForm,
    pendingSemesterUpdates: pendingSemesterUpdatesQuery.data ?? [],
  };
};

export default useHomePage;
