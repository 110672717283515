import styled from 'styled-components';

import ButtonContainerElement from '../../elements/ButtonContainer';

const ButtonContainer = styled(ButtonContainerElement)`
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: 1fr;
    grid-row-gap: 12px;
  }
`;

export default ButtonContainer;
