import type { FinancingPackageItem } from '../../../API';
import type { InstallmentEntry } from '../../../containers/YourFinancingPage/useYourFinancingPage';

import { faPen, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';
import { de } from 'date-fns/locale';
import React from 'react';

import Button from '../Button';
import { H6Typography, Sub2Typography } from '../Typography';

import InstallmentContainer from './InstallmentContainer';
import InstallmentItem from './InstallmentItem';
import formatDescription from './texts/formatDescription';
import formatInstallmentEntryDescription from './texts/formatInstallmentEntryDescription';
import formatTitle from './texts/formatTitle';

import { DateTypes } from '$/types/enums/DateTypes';
import formatPrice from '$/utils/formatPrice';

export type TDisplayInstallmentItem = {
  title: React.ReactNode;
  price: React.ReactNode;
  description?: React.ReactNode;
  rightDescription?: React.ReactNode;
  onEditPress?: () => void;
  onDeletePress?: () => void;
};

interface IInstallmentTable {
  items: TDisplayInstallmentItem[];
  cannotDeleteLastItem?: boolean;
}

export const financingPackageItemToDisplayInstallmentItem = (
  item: FinancingPackageItem
): TDisplayInstallmentItem => ({
  title: formatTitle(item),
  description: formatDescription(item),
  price: formatPrice(item.amount),
});

export const installmentEntryToDisplayInstallmentItem = (
  item: InstallmentEntry
): TDisplayInstallmentItem => ({
  title: format(item.monthDate, DateTypes.DAY_MONTHLONG_YEAR_DOT_SPACE, {
    locale: de,
  }),
  description: formatInstallmentEntryDescription(item),
  price: formatPrice(item.amount),
});

const InstallmentTable = (props: IInstallmentTable) => (
  <InstallmentContainer>
    {props.items?.map((item, index) => (
      <InstallmentItem key={index}>
        <H6Typography bold>{item.title}</H6Typography>
        <H6Typography bold align="right">
          {item.price}
        </H6Typography>
        <Sub2Typography>{item.description}</Sub2Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          {item.rightDescription ?? null}
        </div>
        <div style={{ display: 'flex', columnGap: '22px' }}>
          {item.onDeletePress &&
            !(props.items.length === 1 && props?.cannotDeleteLastItem) && (
              <Button
                title="Löschen"
                variant="text"
                leftIcon={<FontAwesomeIcon icon={faTrash} />}
                buttonProps={{
                  style: {
                    marginTop: '12px',
                    fontSize: '14px',
                    padding: 0,
                  },
                  onClick: item.onDeletePress,
                }}
                textProps={{
                  style: {
                    fontSize: '15px',
                  },
                }}
              />
            )}
          {item.onEditPress && (
            <Button
              title="Ändern"
              variant="text"
              leftIcon={<FontAwesomeIcon icon={faPen} />}
              buttonProps={{
                style: {
                  marginTop: '12px',
                  fontSize: '14px',
                  padding: 0,
                },
                onClick: item.onEditPress,
              }}
              textProps={{
                style: {
                  fontSize: '15px',
                },
              }}
            />
          )}
        </div>
      </InstallmentItem>
    ))}
  </InstallmentContainer>
);

export default InstallmentTable;
