import styled from 'styled-components';

const InstallmentItem = styled('div')<{ previous?: boolean }>`
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;

  > * {
    color: ${({ previous, theme }) =>
      previous ? theme.colors.grey5 : 'initial'};
  }
`;

export default InstallmentItem;
