import React from 'react';

import Container from '../Container';
import { H5Typography } from '../Typography';

import BackgroundContainer from './BackgroundContainer';
import Title from './Title';
import Wrapper from './Wrapper';

interface ISectionProps {
  children: React.ReactNode;
  title: React.ReactNode | string;
  backgroundColor?: string;
  style?: object;
}

const OfferSection = ({ children, title, ...props }: ISectionProps) => (
  <BackgroundContainer {...props}>
    <Title>
      <Container>
        <H5Typography bold style={{ padding: '0px 8px' }}>
          {title}
        </H5Typography>
      </Container>
    </Title>
    <Container>
      <Wrapper>{children}</Wrapper>
    </Container>
  </BackgroundContainer>
);

export default OfferSection;
