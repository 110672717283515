const formatPrice = (
  price: number,
  options?: Intl.NumberFormatOptions
): string => {
  const additionalOptions = {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    ...options,
  };
  return Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    ...additionalOptions,
  }).format(price);
};

export default formatPrice;
