import type { IHomePageProps } from '$/pages';

import React from 'react';

import HomePageView from './HomePageView';
import useHomePage from './useHomePage';

const Home = (props: IHomePageProps) => {
  const state = useHomePage(props);
  return <HomePageView {...props} {...state} />;
};

export default Home;
