import styled from 'styled-components';

const Hr = styled('div')<{ children?: null | undefined }>`
  height: 1px;
  width: 100%;
  margin: 16px 0;
  padding: 0 !important;
  background-color: ${(props) => props.theme.colors.grey3};
`;

export default Hr;
