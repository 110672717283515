import type { FinancingPackageItem } from '../../../../API';

import format from 'date-fns/format';
import { de } from 'date-fns/locale';
import React from 'react';

import { FinancingPackageItemType } from '../../../../API';

import theme from '$/assets/theme';
import { DateTypes } from '$/types/enums/DateTypes';

const formatDescription = (item: FinancingPackageItem) => {
  switch (item.type) {
    case FinancingPackageItemType.MONTHLY: {
      return (
        <>
          <b style={{ color: theme.colors.brandBlue }}>
            Monatlich fortlaufend{' '}
          </b>{' '}
          bis
          <span>
            {' '}
            {format(new Date(item.dateTo), DateTypes.MONTHLONG_YEAR_SPACE, {
              locale: de,
            })}
          </span>
        </>
      );
    }
    case FinancingPackageItemType.ONE_TIME: {
      return <span>Einmalige Zahlung</span>;
    }
    case FinancingPackageItemType.YEARLY: {
      return (
        <>
          <b style={{ color: theme.colors.brandBlue }}>Jährlich fortlaufend </b>{' '}
          bis
          <span>
            {' '}
            {format(new Date(item.dateTo), DateTypes.MONTHLONG_YEAR_SPACE, {
              locale: de,
            })}
          </span>
        </>
      );
    }
    case FinancingPackageItemType.HALF_YEARLY: {
      return (
        <>
          <b style={{ color: theme.colors.brandBlue }}>
            Halbjährlich fortlaufend
          </b>{' '}
          bis
          <span>
            {' '}
            {format(new Date(item.dateTo), DateTypes.MONTHLONG_YEAR_SPACE, {
              locale: de,
            })}
          </span>
        </>
      );
    }
    default: {
      return '';
    }
  }
};

export default formatDescription;
