export enum DateTypes {
    DAY = 'dd',
    LONGMONTH = 'MMMM',
    DAY_MONTH_YEAR_DOT = 'dd.MM.yyyy',
    DAY_MONTHLONG_YEAR_DOT_SPACE = 'dd. MMMM yyyy',
    DAY_MONTH_DAY_FULL_TEXT_DOT = 'DD. MMMM. yyyy',
    MONTH_DAY_YEAR_DOT = 'MM.dd.yyyy',
    MONTH_DAY_YEAR_SLASH = 'MM/dd/yyyy',
    MONTH_YEAR_DOT = 'MM.yyyy',
    MONTH_YEAR_SLASH = 'MM/yyyy',
    MONTHMEDIUM_YEAR_SPACE = 'MMM yyyy',
    MONTHLONG_YEAR_DASH = 'MMMM-yyyy',
    MONTHLONG_YEAR_SPACE = 'MMMM yyyy',
    YEAR_MONTH_DAY_DASH = 'yyyy-MM-dd',
    YEAR_DAY_MONTH_SLASH = 'yyyy/dd/MM',
    YEAR_MONTH_SLASH = 'yyyy/MM',
    YEAR_MONTH_DAY_SLASH = 'yyyy/MM/dd',
}

export enum GermanDateTypes {
    DAY_MONTH_YEAR_DOT = 'TT.MM.JJJJ',
    MONTH_YEAR_DOT = 'MM.JJJJ',
}