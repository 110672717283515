import type { FinancingPackageItem } from '../../../../API';
import type React from 'react';

import add from 'date-fns/add';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import { de } from 'date-fns/locale';
import set from 'date-fns/set';

import { FinancingPackageItemType } from '../../../../API';

import { DateTypes } from '$/types/enums/DateTypes';

const getMonthlyDate = (startDate: Date, endDate: Date): Date => {
  const today = new Date();

  // In the past of startDate
  if (isAfter(startDate, today)) {
    return set(startDate, { date: 1 });
  }

  // In the future of endDate
  if (isBefore(endDate, today)) {
    return set(endDate, { date: 1 });
  }

  return add(set(today, { date: 1 }), { months: 1 });
};

const formatTitle = (
  item: FinancingPackageItem,
  dateFormat = DateTypes.MONTHMEDIUM_YEAR_SPACE
): React.ReactNode | string => {
  switch (item.type) {
    case FinancingPackageItemType.ONE_TIME: {
      return format(new Date(item.dateFrom), dateFormat, { locale: de });
    }
    case FinancingPackageItemType.MONTHLY: {
      return `${format(new Date(item.dateFrom), dateFormat, {
        locale: de,
      })} - ${format(new Date(item.dateTo), dateFormat, {
        locale: de,
      })}`;
    }
    default: {
      return format(
        new Date(
          getMonthlyDate(new Date(item.dateFrom), new Date(item.dateTo))
        ),
        DateTypes.MONTHMEDIUM_YEAR_SPACE,
        { locale: de }
      );
    }
  }
};

export default formatTitle;
