import type { IHomePageProps } from '$/pages';
import type { IHomeStateProps } from './useHomePage';

import format from 'date-fns/format';
import { de } from 'date-fns/locale';
import React from 'react';

import theme from '$/assets/theme';
import HomePage from '$/components/blocks/HomePage';
import SemesterUpdateNotification from '$/components/blocks/HomePage/notifications/SemesterUpdateNotification';
import YearlyUpdateNotification from '$/components/blocks/HomePage/notifications/YearlyUpdateNotification';
import Hr from '$/components/blocks/OfferPage/Hr';
import Button from '$/components/elements/Button';
import ButtonContainer from '$/components/elements/ButtonContainer';
import Container from '$/components/elements/Container';
import InstallmentTable from '$/components/elements/InstallmentTable';
import OfferSection from '$/components/elements/OfferSection';
import { H6Typography, Sub2Typography } from '$/components/elements/Typography';
import { DateTypes } from '$/types/enums/DateTypes';

interface IHomeViewProps extends IHomePageProps, IHomeStateProps {}

const HomePageView = (props: IHomeViewProps) => (
  <HomePage>
    <Container>
      <H6Typography as="h1" bold>
        Hallo {props.userData?.firstName}
      </H6Typography>
    </Container>
    {(props.userData?.changeOfferStatus === 1030 ||
      props.userData?.changeOfferStatus === 1031) && (
      <OfferSection
        title={' Änderungsangebot'}
        backgroundColor={theme.colors.strawberry10}
      >
        <Sub2Typography style={{ margin: '8px 16px' }}>
          Unser Finanzteam hat ein tolles Angebot zusammengestellt, um deine
          Finanzierung auf deine neuen Bedürfnisse anzupassen.
        </Sub2Typography>
        <ButtonContainer columns={'1fr'} style={{ margin: '16px 0 0' }}>
          <Button
            variant={'primary'}
            title={'Zum Angebot'}
            colorStates={{
              unset: 'strawberry75',
              hover: 'strawberry',
              active: 'strawberry',
            }}
            link={{ href: '/offer-overview' }}
          />
        </ButtonContainer>
      </OfferSection>
    )}

    {props.pendingSemesterUpdates.map((semesterUpdate) => (
      <SemesterUpdateNotification
        key={semesterUpdate.id}
        semesterUpdate={semesterUpdate}
        additionalDocuments={props.userData?.additionalDocuments}
      />
    ))}

    {props.changeOfferInProgress && (
      <OfferSection
        title={'Dein Änderungsantrag'}
        backgroundColor={theme.colors.strawberry10}
      >
        <Sub2Typography style={{ margin: '8px 16px' }}>
          Wir haben wichtige Neuigkeiten zu deinem Änderungsantrag für dich.
        </Sub2Typography>
        <ButtonContainer columns={'1fr'} style={{ margin: '16px 0 0' }}>
          <Button
            variant={'primary'}
            title={'Zu den Neuigkeiten'}
            colorStates={{
              unset: 'strawberry75',
              hover: 'strawberry',
              active: 'strawberry',
            }}
            link={{ href: '/change-offer/rejected' }}
          />
        </ButtonContainer>
      </OfferSection>
    )}

    {props.yearlyUpdatePending.map((yearUpdate) => (
      <YearlyUpdateNotification key={yearUpdate.year} yearUpdate={yearUpdate} />
    ))}

    {props.pendingTravelExpensesForm && (
      <OfferSection
        title={'Deine Reisekostenabrechnung'}
        backgroundColor={theme.colors.strawberry10}
      >
        {props.pendingTravelExpensesForm.map(
          (pendingTravelExpensesForm, index) => (
            <React.Fragment key={pendingTravelExpensesForm.sys.id}>
              {index !== 0 && <Hr style={{ margin: '8px 0' }} />}
              <Sub2Typography style={{ margin: '8px 16px' }}>
                Wir haben alles vorbereitet, damit du deine
                Reisekostenerstattung für den Workshop vom{' '}
                <b>
                  {format(
                    new Date(pendingTravelExpensesForm.fields.dateStart),
                    DateTypes.DAY
                  )}
                  {' - '}
                  {format(
                    new Date(pendingTravelExpensesForm.fields.dateEnd),
                    DateTypes.DAY_MONTHLONG_YEAR_DOT_SPACE,
                    {
                      locale: de,
                    }
                  )}{' '}
                  {pendingTravelExpensesForm.fields.name}
                </b>{' '}
                beantragen kannst.
              </Sub2Typography>
              <ButtonContainer columns={'1fr'} style={{ margin: '12px 0 0' }}>
                <Button
                  variant={'primary'}
                  title={'Reisekosten einreichen'}
                  colorStates={{
                    unset: 'strawberry75',
                    hover: 'strawberry',
                    active: 'strawberry',
                  }}
                  link={{
                    href: `/customer-portal/travel-expenses/${pendingTravelExpensesForm.sys.id}`,
                  }}
                />
              </ButtonContainer>
            </React.Fragment>
          )
        )}
      </OfferSection>
    )}
    <HomePage.OfferSection
      title={'Deine Finanzierung'}
      backgroundColor={theme.colors.grey1}
      style={{ paddingTop: '8px' }}
    >
      <Container>
        <div style={{ padding: '0 16px' }}>
          <Sub2Typography>Nächste Zahlung</Sub2Typography>
          <div style={{ margin: '16px 0' }}>
            <InstallmentTable items={props.payments} />
          </div>
        </div>
        <Button
          title={'Zu deiner Finanzierung'}
          colorStates={{
            unset: 'grey6',
            hover: 'grey7',
            active: 'grey7',
            focus: 'grey7',
          }}
          link={{ href: '/financing' }}
        />
      </Container>
    </HomePage.OfferSection>
  </HomePage>
);

export default HomePageView;
