import styled from 'styled-components';

const BackgroundContainer = styled('div')<{ backgroundColor?: string }>`
  position: relative;
  margin-top: 50px;
  padding-top: 15px;
  background-color: ${(props) =>
    props.backgroundColor || props.theme.colors.brandBlue10};
`;

export default BackgroundContainer;
